import * as React from 'react';
import { TablePagination, TablePaginationProps } from '@mui/material';
import { useTranslate } from '../i18n';
import styled from '@emotion/styled';

export interface PaginationProps
  extends Pick<
    TablePaginationProps,
    'count' | 'page' | 'rowsPerPage' | 'rowsPerPageOptions'
  > {
  /**
   * By default, value is 'pagination navigation'. Optionally, override with a custom value.
   */
  e2e?: string;
  a11yLabel?: TablePaginationProps['aria-label'];
  dsOnPageChange: TablePaginationProps['onPageChange'];
  dsOnRowsPerPageChange: TablePaginationProps['onRowsPerPageChange'];
}
const StyledSpan = styled.span`
  border-left: 1px solid;
  padding-left: 4px;
`;

const labelDisplayedRows = (from, to, count) => {
  return (
    <>
      {from}-{to}{' '}
      {!!count && (
        <StyledSpan style={{ borderLeft: '1px solid', paddingLeft: '4px' }}>
          {count}
        </StyledSpan>
      )}
    </>
  );
};

const Pagination: React.FC<PaginationProps> = ({
  a11yLabel,
  e2e,
  count,
  page = 0,
  dsOnPageChange,
  dsOnRowsPerPageChange,
  rowsPerPage = 10,
  rowsPerPageOptions = [5, 10, 15, 20, 30, 50],
}: PaginationProps) => {
  const { translate } = useTranslate();
  return (
    <TablePagination
      aria-label={a11yLabel}
      {...(e2e && { 'data-e2e': `${e2e}-stepper` })}
      onPageChange={dsOnPageChange}
      onRowsPerPageChange={dsOnRowsPerPageChange}
      page={page}
      count={count ?? -1}
      labelRowsPerPage={translate('RowsPerPageLabel')}
      labelDisplayedRows={({ from, to }) => labelDisplayedRows(from, to, count)}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      SelectProps={
        {
          ...(e2e && {
            'data-e2e': `${e2e}-table-pagination-select`,
          }),
        } as any
      }
      showFirstButton={!!count}
      showLastButton={!!count}
      nextIconButtonProps={
        {
          ...(e2e && {
            'data-e2e': `${e2e}-table-pagination-next-button`,
          }),
        } as any
      }
      backIconButtonProps={
        {
          ...(e2e && {
            'data-e2e': `${e2e}-table-pagination-back-button`,
          }),
        } as any
      }
    />
  );
};

export default Pagination;
