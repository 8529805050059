import * as React from 'react';
import {
  Card as MuiCard,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  useTheme,
} from '@mui/material';
import { designTokens } from '../Theme.design-tokens';
import { boxShadows } from '../Theme';
import TitleBar from '../TitleBar';

export interface CardProps {
  children?: React.ReactNode;
  body?: React.ReactNode;
  e2e?: string;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  headerAction?: React.ReactNode;
  /** Unique ID for accessibility purposes. */
  id: string;
  fullWidth?: boolean;
  autoDistance?: boolean;
  space?: 'small' | 'large';
  variant?: 'outlined' | 'elevation';
  titleBar?: boolean;
  noPaddingBottom?: boolean;
}

const Card: React.FC<CardProps> = ({
  id,
  e2e,
  header,
  headerAction,
  body,
  footer,
  fullWidth,
  autoDistance,
  space,
  variant = 'elevation',
  titleBar,
  noPaddingBottom = false,
}: CardProps) => {
  const headerId = `${id}-header`;
  const bodyId = `${id}-body`;
  const theme = useTheme();

  return (
    <MuiCard
      sx={{
        ...(variant === 'outlined' && {
          border: `1px solid ${designTokens.colors.lightContextExtraHigh}`,
        }),
        ...(autoDistance && {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }),
        ...(variant === 'elevation' && {
          boxShadow: boxShadows[1],
        }),
      }}
      variant={variant}
      {...(header && { 'aria-labelledby': headerId })}
      {...(body && { 'aria-describedby': bodyId })}
      {...(e2e && { 'data-e2e': e2e })}
    >
      {header && (
        <CardHeader
          sx={{
            ...(space === 'small' && {
              padding: theme.spacing(2),
            }),
            ...(space === 'large' && {
              padding: theme.spacing(2.5),
            }),
            ...(noPaddingBottom && {
              paddingBottom: 0,
            }),
          }}
          id={headerId}
          title={
            <>
              {titleBar && <TitleBar />}
              <Box sx={{ flexGrow: 1 }}>{header}</Box>
            </>
          }
          action={headerAction}
          {...(e2e && { 'data-e2e': `${e2e}-header` })}
        />
      )}
      {body && (
        <CardContent
          sx={{
            ...(space === 'small' && {
              padding: theme.spacing(2),
            }),
            ...(space === 'large' && {
              padding: theme.spacing(2.5),
            }),
            ...(fullWidth && {
              margin: 0,
              padding: 0,
            }),
          }}
          id={bodyId}
          {...(e2e && { 'data-e2e': `${e2e}-body` })}
        >
          {body}
        </CardContent>
      )}
      {footer && (
        <CardActions
          sx={{
            ...(space === 'small' && {
              padding: theme.spacing(2),
            }),
            ...(space === 'large' && {
              padding: theme.spacing(2.5),
            }),
          }}
          {...(e2e && { 'data-e2e': `${e2e}-footer` })}
        >
          {footer}
        </CardActions>
      )}
    </MuiCard>
  );
};

export default Card;
